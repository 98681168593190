<script setup>
	const { image, icon, headline, copy, button, background, padding } = defineProps({
		image: {
			type: [Object, Boolean],
			required: false,
			default: false,
		},
		icon: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		iconSize: {
			type: String,
			required: false,
			default: 'medium',
			validator: (value) => {
				return ['small', 'medium', 'large'].includes(value);
			},
		},
		headline: {
			type: [Object, Boolean],
			required: false,
			default: false,
			validator: (value) => {
				return !value || (value.tag && value.html && value.class);
			},
		},
		copy: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		copyClass: {
			type: String,
			required: false,
			default: '',
		},
		button: {
			type: [Object, Boolean],
			required: false,
			default: false,
		},
		background: {
			type: String,
			required: false,
			default: 'white',
			validator: (value) => {
				return ['white', 'dark-gray', 'red', 'transparent'].includes(value);
			},
		},
		padding: {
			type: String,
			required: false,
			default: 'medium',
			validator: (value) => {
				return ['none', 'small', 'medium', 'large'].includes(value);
			},
		},
	});

	// TODO: add to this function as we continue adding backgrounds
	const buttonColor = computed(() => {
		if (background == 'transparent') return 'red';
		if (background == 'white') return 'red';
		if (background == 'dark-gray') return 'white';
		if (background == 'red') return 'transparent';
	});
</script>

<template>
	<div class="fragment-card-box" :class="`${background} ${padding}`">
		<MessImage v-if="image" :image="image" />
		<ProjectSvg v-if="icon" :type="icon" :class="iconSize" />
		<MessHtml v-if="headline?.html" :html="headline?.html" :class="headline?.class" :tag="headline?.tag" />
		<MessHtml v-if="copy" :html="copy" :class="copyClass" class="copy" tag="div" />
		<ProjectButton v-if="button?.label && button?.url" :button="button" :version="buttonColor" />
		<!-- Used to pass more items within the box -->
		<slot />
	</div>
</template>

<style lang="scss">
	.fragment-card-box {
		transition: var(--text-reveal);
		transition-property: opacity, transform, clip-path;
		// Image
		.mess-image {
			margin-bottom: 3rem;
		}
		// Icon
		.mess-svg {
			&.small {
				width: 1.25rem;
				height: 1.25rem;
				margin-bottom: 1rem;
			}
			&.medium {
				width: 2.25rem;
				height: 2.25rem;
				margin-bottom: 1.5rem;
			}
			&.large {
				width: 5rem;
				height: 5rem;
				margin-bottom: 2rem;
			}
		}
		.mess-html.copy,
		.mess-html.copy p {
			font-size: 14px;
			@media (min-width: $tablet) {
				font-size: calc(16 / var(--base-fs) * 1rem);
			}
		}
		// Button
		.mess-button {
			margin-top: 2rem;
		}
		// Background
		&.white {
			background-color: var(--white);
		}
		&.dark-gray {
			background-color: var(--gray-900);
			color: var(--white);
			.mess-svg {
				path {
					fill: var(--white);
				}
			}
		}
		&.red {
			background-color: var(--red);
			color: var(--white);
			.mess-svg {
				path {
					fill: var(--white);
				}
			}
		}
		&.transparent {
			background-color: transparent;
		}
		// Padding
		&.none {
			padding: 0;
		}
		&.small {
			padding: 2rem;
		}
		&.medium {
			padding: 2.5rem;
		}
		&.large {
			padding: 3.125rem;
		}
	}
</style>
